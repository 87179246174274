// Generated by Framer (9f57636)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WIW36gZo0", "z6JK_d0O1", "MgRg_V0dD"];

const variantClassNames = {MgRg_V0dD: "framer-v-md95h7", WIW36gZo0: "framer-v-1v3uthd", z6JK_d0O1: "framer-v-x69baa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Large: "WIW36gZo0", Medium: "z6JK_d0O1", Small: "MgRg_V0dD"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "WIW36gZo0", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WIW36gZo0", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Rec33", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1v3uthd", className)} data-framer-name={"Large"} layoutDependency={layoutDependency} layoutId={"WIW36gZo0"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition} {...addPropertyOverrides({MgRg_V0dD: {"data-framer-name": "Small"}, z6JK_d0O1: {"data-framer-name": "Medium"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Rec33 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Rec33 * { box-sizing: border-box; }", ".framer-Rec33 .framer-1v3uthd { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 300px; justify-content: flex-start; padding: 0px 0px 0px 0px; position: relative; width: 1280px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Rec33 .framer-1v3uthd { gap: 0px; } .framer-Rec33 .framer-1v3uthd > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-Rec33 .framer-1v3uthd > :first-child { margin-top: 0px; } .framer-Rec33 .framer-1v3uthd > :last-child { margin-bottom: 0px; } }", ".framer-Rec33.framer-v-x69baa .framer-1v3uthd { height: 300px; width: 1024px; }", ".framer-Rec33.framer-v-md95h7 .framer-1v3uthd { height: 300px; width: 768px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 1280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"z6JK_d0O1":{"layout":["fixed","fixed"]},"MgRg_V0dD":{"layout":["fixed","fixed"]}}}
 */
const FramerIUyoIXMSu: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerIUyoIXMSu;

FramerIUyoIXMSu.displayName = "Container";

FramerIUyoIXMSu.defaultProps = {height: 300, width: 1280};

addPropertyControls(FramerIUyoIXMSu, {variant: {options: ["WIW36gZo0", "z6JK_d0O1", "MgRg_V0dD"], optionTitles: ["Large", "Medium", "Small"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerIUyoIXMSu, [])